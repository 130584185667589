


















import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import LessonVideoCurtain from '@/components/views/lesson/LessonVideoCurtain.vue'
import LessonCommonView from '@/components/views/lesson/LessonCommonView.vue'
import MentorLessonsModule from '@/store/modules/mentor/lessons'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import ManagerMentorsModule from '@/store/modules/manager/mentors'
import CurrentTimeMixin from '@/mixins/CurrentTimeMixin'

@Component({
  components: {
    LessonCommonView,
    LessonVideoCurtain,
  },
})
export default class MentorsItemLesson extends Mixins(MasterGroupMixin, CurrentTimeMixin) {
  private get lessonID () {
    return +this.$route.params.lessonID
  }

  private get mentorID () {
    return +this.$route.params.mentorID
  }

  private get breadcrumbs () {
    return [
      { name: 'Наставники', path: '/manager/education/mentors' },
      { name: this.mentor ? `${this.mentor.name} ${this.mentor.surname} ` : 'Профиль наставника', path: `/manager/education/mentors/${this.mentorID}/lessons` },
    ]
  }

  private get lesson () {
    return MentorLessonsModule.lesson
  }

  private get mentor () {
    return ManagerMentorsModule.mentor
  }

  private fetchMentor () {
    return ManagerMentorsModule.fetchMentor(+this.mentorID)
      .catch((error) => {
        this.notifyError(error)
      })
  }

  private mounted () {
    this.fetchMentor()
      .then(() => {
        this.syncMasterGroup()
          .then(this.fetchLesson)
      })
      .catch(this.notifyError)
  }

  private fetchLesson() {
    MentorLessonsModule.fetchLesson({
      lessonId: this.lessonID,
      masterGroupId: this.currentMasterGroupID,
    })
      .catch((error) => {
        this.notifyError(error)
        this.$router.push({ name: 'manager.education.lessons' })
      })
  }

  private handleRedirectEdit() {
    this.$router.push({
      name: 'manager.education.lessons.item.edit',
      params: {
        groupID: this.currentMasterGroupID.toString(),
        lessonID: this.lessonID.toString(),
      },
    })
  }

  private metaInfo (): MetaInfo {
    return {
      title: this.lesson ? this.lesson.title : 'Урок',
    }
  }
}
